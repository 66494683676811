import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private action: string = '';
  private loading: boolean = false;
  private enabled: boolean = true;

  constructor() { }

  setLoading(loading: boolean) {
    this.loading = loading && this.enabled;
  }

  getLoading(): boolean {
    return this.loading;
  }

  enable() {
    this.enabled = true;
  }

  disable() {
    this.enabled = false;
  }

  setAction(action: string) {
    this.action = action;
  }

  getAction(): string {
    return this.action;
  }
}